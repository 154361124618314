import { takeLatest, call, put, select } from 'redux-saga/effects';
import { getInventory } from 'yoda-interfaces/lib/Product/InventoryApi';
import { selectSizeBasedOnDeviceType, selectFeatureFlags } from '../selectors/ContextSelector';
import { selectProductDetailsBySource } from '../selectors/PdpNameSpaceSelector';
import {
    INVENTORY_GET_SUCCESS,
    INVENTORY_GET_ERROR,
    INVENTORY_GET_REQUEST,
} from '../actionTypes/InventoryActionTypes';
import { getLocalizationZipcode } from '../helpers/Utils/utils';

function* InventorySaga(inventoryPayload) {
    try {
        inventoryPayload.payload.deviceSize = yield select(selectSizeBasedOnDeviceType);
        inventoryPayload.payload.geoZip = getLocalizationZipcode();
        const featureFlags = yield select(selectFeatureFlags);
        const { sourceMiniPDP } = inventoryPayload.payload;
        const productDetails = yield select(selectProductDetailsBySource(sourceMiniPDP));
        const inventory = yield call(
            getInventory,
            inventoryPayload.payload,
            featureFlags,
            productDetails
        );

        yield put({ type: INVENTORY_GET_SUCCESS, inventory, sourceMiniPDP });
    } catch (error) {
        yield put({
            type: INVENTORY_GET_ERROR,
            error,
            sourceMiniPDP: inventoryPayload.payload.fromMiniPDP,
        });
    }
}

const watchInventorySaga = function* watchInventorySaga() {
    yield takeLatest(INVENTORY_GET_REQUEST, InventorySaga);
};

watchInventorySaga.sagaName = 'watchInventorySaga';

export default watchInventorySaga;
